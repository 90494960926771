<template>
  <b-modal size="md"  id="newReportModal"  v-model="showNewReportPopup" :title="getTitle">
    <b-row>
        <b-col cols="6">              
            <h3>{{$t('reports.newReportModal.name')}}</h3>
        </b-col>
        <b-col cols="6">              
            <h3>{{$t('reports.newReportModal.type')}}</h3>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="6">              
            <b-form-input ref="newReport" v-model="reportName" v-on:keyup.enter="saveReport" placeholder=""></b-form-input>
        </b-col>
        <b-col cols="6">
            <b-dropdown :text="selectedReportType">
                <b-dropdown-item v-for="option in reportTypeOptions" :key="option" :value="option" @click="selectedReportType = option">
                    {{option}}
                </b-dropdown-item>
            </b-dropdown>
        </b-col>
    </b-row>
    <div class="spacer m"></div>
    <h3>{{$t('reports.newReportModal.choosePeriod')}}</h3>
    <b-row>
        <b-col cols="6">
            <p class="smallText">{{$t('reports.newReportModal.from')}}</p>
            <b-form-datepicker :locale="$i18n.locale" id="fromDatePicker" v-model="fromDate"></b-form-datepicker>
        </b-col>
        <b-col cols="6">
            <p class="smallText">{{$t('reports.newReportModal.till')}}</p>
            <b-form-datepicker :locale="$i18n.locale" id="toDatePicker" v-model="toDate"></b-form-datepicker>
        </b-col>
    </b-row>
    
    <b-row v-if="loading">
        <b-col cols="12" class="text-center">
            <div class="spacer m"></div>
                <LoadingAnimation />
        </b-col>
    </b-row>
    
    <template class="poolinqModalFooter" #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="danger" @click="cancel()">
            {{$t('general.buttons.cancel')}}
        </b-button>
        <b-button :disabled="reportName == ''" size="sm" variant="success" @click="saveReport()">
            {{$t('general.buttons.save')}}
        </b-button>
    </template>
</b-modal>
</template>

<script>
    import DateTimeService from '@/services/DateTimeService'
    import DataService from '@/services/StrapiService'
    import { mapGetters } from 'vuex'
    import LoadingAnimation from '@/components/LoadingAnimation.vue'

    export default {
        name: 'NewReportModal',
        components: { LoadingAnimation },
        data: function () {
            return {
                loading: false,
                showNewReportPopup: false,     
                reportName: '',
                reportId: null,
                report: null,          
                reportTypeOptions: [
                    this.$t('activities.soldWines'),
                    this.$t('activities.movedWines'),
                    this.$t('activities.addedWines'),
                    this.$t('activities.removedWines')
                ],
                selectedReportType: this.$t('activities.soldWines'),
                reportTypeValues: {
                    [this.$t('activities.soldWines')]: 'sold',
                    [this.$t('activities.movedWines')]: 'moved',
                    [this.$t('activities.addedWines')]: 'added',
                    [this.$t('activities.removedWines')]: 'deleted'
                },
                fromDate: '',
                toDate: '',
            }
        },
        created () {
        },
        methods: {
            showModal(id) {
                this.showNewReportPopup = true
                this.reportId = id
                setTimeout(x => {
                    console.log(x)
                    this.$nextTick(() => this.setFocus()); // this works great, just watch out with going to fast !!!
                }, 100);
                this.loadReport()
            },
            resetModal() {
                this.loading = false
                this.reportId = null
                this.reportName = ''
                this.report = null,
                this.selectedReportType = this.$t('activities.soldWines')
            },
            setFocus: function() {
                // Note, you need to add a ref="search" attribute to your input.
                this.$refs.newReport.focus();
            },
            async loadReport() {
                if (this.reportId) {
                    this.loading = true
                    await DataService.getReport(this.reportId).then((response) => {
                        this.report = response['data'].data
                        this.reportId = response['data'].data.id
                        this.reportName = response['data'].data.attributes.title
                        var fromDate = DateTimeService.dateToString(new Date(response['data'].data.attributes.dateFrom))
                        this.fromDate = fromDate
                        this.toDate = response['data'].data.attributes.dateTo
                        this.selectedReportType = this.getReportTypeByValue(response['data'].data.attributes.type)

                    }).catch((err) => {
                        console.log(err)
                        this.loading = false
                    })

                    this.loading = false
                } else {
                    this.fromDate = ''
                    this.toDate= ''
                    this.selectedReportType =  this.$t('activities.soldWines')
                    this.reportName = ''
                }
            },
            async saveReport() {
                this.loading = true
                var valid = true

                if (this.reportName === "") {
                    valid = false
                }

                if (! this.selectedReportType) {
                    valid = false
                }

                if (! this.fromDate) {
                    valid = false
                }

                 if (! this.toDate) {
                    valid = false
                }

                if (!valid) {
                    var alertobject = {
                        text: this.$t('reports.newReportModal.errorMessage'),
                        variant: 'danger'
                    }
                    this.$store.commit('setAlertText', alertobject)
                    this.loading = false
                    return
                }

                this.report = this.composeReportFromFields()
                if (this.reportId != null) {
                    //update
                    this.loading = true

                    await DataService.updateReport(this.reportId, this.report).then(() => {
                        this.showNewReportPopup = false
                        this.$store.commit('reloadVenue')
                        this.$store.commit('reloadReports')
                        this.resetModal()
                        
                    }).catch((err) => {
                        console.log(err)
                        this.loading = false
                    })

                    this.loading = false
                } else {
                    //create
                    this.loading = true

                    await DataService.createReport(this.report).then(() => {
                        this.showNewReportPopup = false
                        this.$store.commit('reloadVenue')
                        this.$store.commit('reloadReports')
                        this.resetModal()
                      
                    }).catch((err) => {
                        console.log(err)
                        this.loading = false
                    })

                    this.loading = false
                }
            },
            composeReportFromFields() {
                if (this.report == null) {
                    this.report = {}
                }
                var venueId = this.getVenue.id
                const from = DateTimeService.dateToString(new Date(this.fromDate), false)
                const to = DateTimeService.dateToString(new Date(this.toDate), true)
                const dateRange = '&filters[createdAt][$gte]=' + encodeURIComponent(from) + '&filters[createdAt][$lte]=' + encodeURIComponent(to)
                let filterQuery = ''
                const reportType = this.reportTypeValues[this.selectedReportType]
                switch(reportType) {
                    case 'sold':
                        filterQuery = 'filters[type]=change_amount&filters[revenue]=true'+dateRange
                        break
                    case 'sold_nothing':
                        filterQuery = 'filters[$or][0][revenue][$null]=true&filters[$or][1][revenue]=false'+dateRange
                        break
                    case 'moved':
                        filterQuery = 'filters[type]=move_amount&filters[newVenue][id][$null]=false'+dateRange
                        break
                    case 'sold_all':
                        filterQuery = 'filters[before][$gt]=0&filters[after][$eq]=0&filters[type][$eq]=change_amount&filters[revenue]=true&filters[venueWine][amount]=0'+dateRange
                        break
                    case 'added':
                        filterQuery = 'filters[type]=change_without_revenue&filters[type]=change_amount&filters[revenue]=false&filters[beforeAfterDiff][$gt]=0'+dateRange
                        break
                    case 'deleted':
                        filterQuery = 'filters[type]=change_without_revenue&filters[type]=change_amount&filters[revenue]=false&filters[beforeAfterDiff][$lt]=0'+dateRange
                        break
                }
                return {
                    'id': this.reportId,
                    'filterQuery': filterQuery,
                    'dateFrom': from,
                    'dateTo': to,
                    'type': reportType,
                    'title': this.reportName,
                    'venue': venueId,
                }
            },
            getReportTypeByValue(value) {
                const result = Object.keys(this.reportTypeValues).find(key => this.reportTypeValues[key] === value)
                if (result) {
                    return result
                } else {
                    return this.$t('activities.soldWines')
                }
            }
        },
        computed: {
            getTitle() {
                return (this.reportId != null) ? this.$t('reports.newReportModal.titleEditReport') : this.$t('reports.newReportModal.titleNewReport')
            },
            ...mapGetters(['getVenue'])
        },
    }
</script>
